<template>
  <PublicLayout>
    <div class="container">
      <img
        src="@/assets/img_intro.png"
        class="img-intro"
        alt=""
      >

      <h1 v-t="'SiteMap.title'" />

      <div class="section">
        <div class="partner-list">
          <h1>TODO</h1>
        </div>
      </div>
    </div>
  </PublicLayout>
</template>

<script>
import PublicLayout from '@/router/layouts/PublicLayout.vue'

export default {
  name: 'SiteMap',
  components: {
    PublicLayout
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.container {
  position: relative;
  padding: 40px 15% 0 15%;
  height: 1000px;
  overflow-y: auto;
  background-color: $public-bg;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $color-light-text;

  .img-intro {
    position: absolute;
    top: -25px;
    left: -150px;
    height: 800px;
    z-index: 0;
  }

  h1 {
    font-size: 40px;
    margin-bottom: 36px;
  }

  .section {
    margin-top: 80px;
    height: 300px;
    width: 832px;
    position: relative;
    background-color: $color-body-bg;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;

    .partner-list {
      color: black;
    }
  }
}

</style>
